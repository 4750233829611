export const colors = {
	primary: {
		50: "#FEFCE7",
		100: "#FBF7BB",
		200: "#F9F390",
		300: "#F7EE64",
		400: "#F5E938",
		500: "#F2E40D",
		600: "#C2B60A",
		700: "#918908",
		800: "#615B05",
		900: "#302E03",
	},
	secondary: {
		50: "#F3F3F2",
		100: "#DDDDDA",
		200: "#C6C6C2",
		300: "#B0B0AA",
		400: "#9A9A93",
		500: "#84847B",
		600: "#6A6A62",
		700: "#4F4F4A",
		800: "#353531",
		900: "#1A1A19",
	},
};
