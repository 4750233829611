import React from "react";
import {observer } from "mobx-react-lite";

interface IGlobalStore {
}

const GlobalStoreContext = React.createContext<IGlobalStore | null>(null);

export const GlobalStoreProvider = observer((props) => {
	return (
		<GlobalStoreContext.Provider value={null}>
			{props.children}
		</GlobalStoreContext.Provider>
	);
});

export const useGlobalStore = () => {
	const store = React.useContext(GlobalStoreContext);
	if (!store) {
		throw new Error("Cannot Access Store outside it's context");
	}
	return store;
};
