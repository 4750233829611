import { ComponentStyleConfig } from "@chakra-ui/react";
import { theme } from "@chakra-ui/theme";
import spacing from "~/themes/spacing";

export const Input: ComponentStyleConfig = {
	defaultProps: {
		variant: "outlined",
	},
	variants: {
		solid: (props) => ({
			...theme.components.Button.variants.solid(props),
			field: {
				color: "primary.800",
				bg: "gray.100",
				_hover: {
					bg: "gray.200",
				},
			},
		}),
		outlined: {
			field: {
				borderWidth: spacing.s2,
				borderRadius: spacing.s10,
			},
		},
	},
};
