import { Input } from "./Input";
import { Modal } from "./Modal";
import { Heading } from "./Heading";
import { Text } from "./Text";
import { Button } from "./Button";
import { Card } from "./Card";
import { Radio } from "./Radio";

export const components = {
	Input,
	Modal,
	Heading,
	Text,
	Button,
	Card,
	Radio,
};
