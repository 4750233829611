import { ComponentStyleConfig } from "@chakra-ui/react";
import spacing from "../../../themes/spacing";

export const Card: ComponentStyleConfig = {
	baseStyle: {
		w: "100%",
		bg: "white",
		position: "relative",
		rounded: {
			base: 0,
			sm: spacing.s10,
		},
	},
	defaultProps: {
		size: "md",
		variant: "filled",
	},
	sizes: {
		sm: {
			fontSize: "sm",
			px: spacing.s4,
			py: spacing.s4,
		},
		md: {
			fontSize: "md",
			px: spacing.s8,
			py: spacing.s8,
		},
		footerMd: {
			fontSize: "md",
			px: spacing.s8,
			py: spacing.s8,
			pb: 20,
		},
	},
	variants: {
		details: {
			position: "relative",
			maxW: {
				base:"100%",
				lg:"40%",
				xl: "30%",
			},
		},
	},
};
