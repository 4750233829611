import React from "react";
import {
	Flex,
	CenterProps,
	Heading,
	Image,
} from "@chakra-ui/react";
import { InfoText } from "~/components";
import spacing from "~/themes/spacing";

interface IProps extends CenterProps {
	src: string;
	doctorName: string;
	doctorAge: string;
	description: string;
}

export const InfoDoctorComponent: React.FC<IProps> = (props) => {

	const {
		src, doctorAge,
		doctorName, description,
		...rest
	} = props;
	return (
		<Flex
			alignItems={{ base: "center", lg: "flex-start" }}
			flexWrap={{ base: "wrap", lg: "initial" }}
			flexDirection={{ base: "column", lg: "row" }}
			{...rest}
		>

			<Flex
				borderRadius="full"
				borderColor="primary.700"
				borderWidth={8}
			>
				<Image
					width="100%"
					minW="120px"
					maxW="140px"
					borderRadius="full"
					src={src}
					alt="medico 1"
				/>
			</Flex>

			<Flex
				flexDirection="column"
				maxW={{ base: "100%", lg: "600px" }}
				pb={{ base: 5, lg: 10 }}
				px={4}
				pt={{ base: "initial", md: spacing.s8, lg: "initial" }}
			>
				<Flex
					flexDirection="column"
					px={{ base: spacing.s4, md: spacing.s16, lg: "initial" }}>
					<Heading
						fontSize={{ base: "md", lg: "lg" }}
						fontWeight="bold"
						textAlign="left"
						mt={{ base: spacing.s8, md: "initial", lg: spacing.s8 }}
					>
						{doctorName}
					</Heading>
					<Heading
						fontSize={{ base: "md", lg: "lg" }}
						fontWeight="bold"
						textAlign="left"
						mb={spacing.s6}
					>
						{doctorAge}
					</Heading>
				</Flex>
				<InfoText
					px={{ base: spacing.s4, md: spacing.s16, lg: "initial" }}
					textAlign="justify"
					fontSizeText="sm"
					text={description}
				/>
			</Flex>
		</Flex>
	);
};
