const spacing = {
	s1: 1,
	s2: 2,
	s3: 3,
	s4: 4,
	s5: 5,
	s6: 6,
	s8: 8,
	s10: 10,
	s12: 12,
	s16: 16,
	s20: 20,
	s24: 24,
	s40: 40,
};

export default spacing;
