import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { AppProps } from "next/app";
import { ContactButtons } from "~/components/ContactButtons";
import { GlobalStoreProvider } from "~/contexts/useGlobalContext";
import { bootAPI } from "~/resources/api";
import strings from "~/resources/strings";
import { theme } from "~/themes";
import { MainLayout } from "~/layout";

const App = (props: AppProps) => {
	const { Component, pageProps } = props;
	return (
		<ChakraProvider theme={theme}>
			<GlobalStoreProvider>
				<MainLayout>
					<Component {...pageProps} />
				</MainLayout>
				<ContactButtons />
			</GlobalStoreProvider>
		</ChakraProvider>
	);
};

export default App;

bootAPI(strings);
