import React from "react";
import {
	Box,
	Button,
	Center,
	CenterProps,
	Heading,
	Text,
} from "@chakra-ui/react";
import { TextInput } from "..";
import spacing from "~/themes/spacing";
import { api, strings } from "~/resources";
import { TextareaInput } from "../TextareaInput";

export type ContactField = "name" | "email" | "phone" | "message";

interface IProps extends CenterProps {
	contact: api.NewContact;
	loading: boolean;
	onHandleChange: (field: ContactField, value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onSubmit: () => void;
}

const componentStrings = strings.components.contactForm;

export const ContactForm: React.FC<IProps> = (props) => {

	const {
		contact,
		loading,
		onHandleChange,
		onSubmit,
		...rest
	} = props;

	return (
		<Center id="contact" w="100%" flexDir="column" {...rest}>
			<Heading
				fontSize="lg"
				fontWeight="bold"
			>
				{componentStrings.title}
			</Heading>
			<Text textAlign="center" py="20px">
				{componentStrings.tip}
			</Text>
			<Box width="100%">
				<TextInput
					labelText={componentStrings.name}
					placeholder={componentStrings.name}
					value={contact.name}
					onChange={(e) => onHandleChange("name", e)}
				/>
				<TextInput
					boxProps={{ paddingY: spacing.s4 }}
					labelText={componentStrings.email}
					placeholder={componentStrings.email}
					value={contact.email}
					onChange={(e) => onHandleChange("email", e)}
				/>
				<TextInput
					mask={"(99) 99999-9999"}
					labelText={componentStrings.phone}
					placeholder={componentStrings.phone}
					value={contact.phone}
					onChange={(e) => onHandleChange("phone", e)}
				/>
				<TextareaInput
					boxProps={{ paddingY: spacing.s4 }}
					labelText={componentStrings.message}
					placeholder={componentStrings.message}
					value={contact.message}
					onChange={(e) => onHandleChange("message", e)}
				/>
			</Box>
			<Button
				mt={spacing.s8}
				onClick={onSubmit}
				disabled={loading}
				bg="#988F08"
				isLoading={loading}
			>
				{componentStrings.send}
			</Button>
		</Center>
	);
};
