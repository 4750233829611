import { ComponentStyleConfig } from "@chakra-ui/react";
import { modalAnatomy } from "@chakra-ui/anatomy";
import spacing from "../../spacing";

const modalParts = modalAnatomy.classNames;
const { dialog, header, body, footer  } = modalParts;

const { s4, s8, s10 } = spacing;

export const Modal: ComponentStyleConfig = {
	parts: [dialog, header, body, footer],
	baseStyle: {
		header: {
			px: s10,
			pt: s8,
			pb: s4,
		},
		body: {
			px: s10,
			py: s4,
		},
		footer: {
			px: s10,
			pt: s4,
			pb: s8,
			justifyContent: "center",
		},
	},
};
