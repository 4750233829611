import React from "react";
import {
	Center,
} from "@chakra-ui/react";
import { ContactForm } from "..";
import { ContactField } from "../ContactForm";
import { api } from "~/resources";
import spacing from "~/themes/spacing";

interface IProps {
	contact: api.NewContact;
	onHandleChange: (field: ContactField, value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onSubmit: () => void;
	loading: boolean;
}
export const SectionContact: React.FC<IProps> = (props: IProps) => {

	const {
		contact, onSubmit,
		onHandleChange, loading,
	} = props;

	return(
		<Center
			id="contact"
			backgroundColor="gray.200"
			width="100%"
			flexDirection="column"
			py={spacing.s20}
			px={{ base: "20px", md: "0px" }}
		>
			<ContactForm
				maxW={550}
				contact={contact}
				onHandleChange={onHandleChange}
				onSubmit={onSubmit}
				loading={loading}
			/>
		</Center>
	);
};
