/* eslint-disable max-len */
import LocalizedStrings from "localized-strings";

const strings = new LocalizedStrings({
	ptBr: {
		ogTags: {
			name: "Contabilidade Bomfim & Ferraz",
		},
		enum: {
			PaymentMethod: {
				pix: "PIX",
				cash: "Dinheiro",
				creditCard: "Cartão de Crédito",
				debitCard: "Cartão de Débito",
			},
		},
		error: {
			default: "Um erro ocorreu, tente mais tarde.",
			invalidArgument: "Argumento inválido",
			stillLoading: "Ainda Carregando, aguarde.",
		},
		screen: {
			notFound: {
				title: "Página não encontrada",
				helper: "Você será redirecionado para página principal",
			},
			home: {
				firstProductName: "5 ÁGUAS",
				firstProductPrice: "R$ 50,00",
				secondProductName: "3 ÁGUAS",
				secondProductPrice: "R$ 36,00",
				thirdProductName: "2 ÁGUAS",
				thirdProductPrice: "R$ 30,00",
				toOrderButton: "Fazer Pedido",
			},
			faq: {
				title: "Perguntas Frequentes",
				faqLeft: [
					{
						title: "Como é feito o atendimento da Bomfim Ferraz?",
						description: "Atendemos de forma presencial e digital (whatsapp, telefone, app e vídeo conferência). ",
					},
					{
						title: "Atendem Lucro Real? ",
						description: "Sim, atendemos clientes em todos os regimes de tributação (Simples, Lucro Real e Lucro Presumido).",
					},
					{
						title: "Como funciona o atendimento aos médicos?",
						description: `A Bomfim & Ferraz desenvolveu um atendimento exclusivo para os profissionais de saúde, com emissão das notas, gestão dos contratos (emissão de certidões, cadastros, etc.), 
						consultoria tributária de forma a reduzir o custo com impostos e taxas, serviços contábeis e financeiros e por fim um profissional que prestará atendimento exclusivo ao médico.`,
					},
					{
						title: "Não terei problemas com o fisco? ",
						description: "Fique tranquilo, temos 15 anos de experiência no mercado, com 15 contadores, advogados, e 8 especialistas preparados e empenhados em zelar pelo atendimento a todas as exigências legais,dando tranquilidade para que você possa direcionar os seus esforços para sua atividade empresarial. ",
					},
				],
				faqRight: [
					{
						title: "Vocês possuem assessoria jurídica? ",
						description: "Sim, disponibilizamos assessoria preliminar e orientação com advogado especializado e com grande experiência.",
					},
					{
						title: "Como faço para migrar de contabilidade? ",
						description: "Informe o seu atual prestador de serviços e deixe o resto com a gente. Simples e com toda a segurança.",
					},
					{
						title: "Vocês fazem abertura de empresa?",
						description: "Sim, temos um Contador que atua exclusivamente na legalização de empresas. Entre em contato que prestaremos todas as orientações necessárias. ",
					},
				],
			},
			receipt: {
				title: "Recibo",
			},
			contact: {
				success: "Contato enviado com sucesso!",
			},
			order: {
				validate: {
					name: "Por favor, insira um nome",
					email: "Por favor, insira um email",
					phone: "Por favor, insira um número de telefone",
					street: "Por favor, insira uma rua",
					neighborhood: "Por favor, insira um bairro",
					number: "Por favor, insira um número",
				},
				success: {
					orderCreated: "Pedido realizado com sucesso!",
				},
			},
			aboutUs: {
				description: `
				A Bomfim & Ferraz Contadores Associados é um dos mais tradicionais escritórios de contabilidade da Região Metropolitana de Salvador. Atuando como um centro de negócios integrados, está ao lado dos seus clientes em diversas frentes: execução dos serviços contábeis; consultoria tributária; consultoria financeira, assessoria jurídica; Auditoria e recuperação de créditos tributários.
				Atualmente são 10 contadores e 12 especialistas trabalhando diuturnamente para o sucesso de nossas parcerias. A equipe da Bomfim & Ferraz é o maior patrimônio da empresa, sendo sua formação, satisfação e sucesso uma de suas maiores prioridades.
				Hoje, pode-se dizer que são disponibilizados  15 anos de experiência  e knowhow aos seus  clientes, sempre zelando pela qualidade no atendimento, satisfação e, sobretudo, no crescimento sustentável de todos os seus parceiros.
				`,
				title: "SOBRE NÓS",
			},

			taxStudio: {
				description: `
				A Studio Fiscal é a maior empresa do Brasil na recuperação de créditos tributários. Com mais de 250 especialistas  e 15 mil clientes atendidos, mostrou-se a parceira ideal para que a Bomfim & Ferraz pudesse  prestar mais essa assessoria a seus clientes.
				Em virtude da complexidade e constantes alterações da legislação tributária em nosso país, aproximadamente 95% das empresas pagam mais impostos do que deveriam.
				É nesse momento que entra a revisão tributária, quando são analisados os últimos 5 anos contábeis da empresa e, após a revisão e constatado o pagamento indevido, é solicitada a devolução dos valores, obviamente respeitando a legislação.
				Simples, sem custos antecipados, totalmente dentro da lei e contando com a experiência e competência da Studio Fiscal e da Bomfim & Ferraz. 
				`,
				buttonText: "Fale com a nossa equipe",
			},
		},
		routeNames: {
			home: "Home",
			services: "Serviços",
			accountingForDoctors: "Contabilidade para Médicos",
			faq: "FAQ",
			blog: "Blog",
			aboutUs: "Quem somos",
			logout: "Sair",
		},
		components: {
			navbar: {
				close: "Fechar",
			},
			cardPost: {
				readMore: "Ler Mais",
			},
			infoDoctorsSection: {
				left: {
					src: "/doctor1.png",
					description: `
					A Bomfim & Ferraz esteve presente em todos os momentos desde a minha formatura.
					Orientou qual a melhor opção na hora de abrir minha empresa e escolher o regime de tributação mais adequado.
					Faz a emissão de minhas notas fiscais, cadastro junto aos hospitais, clínicas e entes públicos, apuração dos impostos… enfim toda a parte burocrática, para que eu possa direcionar todos os meus esforços ao meu trabalho.
					Estou muito feliz com a parceria, pois sei que estou sendo bem assessorada, tendo o menor custo possível com impostos e contando com amigos sempre que preciso.
					`,
					doctorAge: "34 anos",
					doctorName: "Dra. Fabiana Antunes",
				},
				right: {
					src: "/doctor2.png",
					description: `
					Recomendo a Bomfim & Ferraz a todos os colegas que estão começando na profissão. Fui surpreendido por uma contabilidade que está ao meu lado sempre que preciso, zelando pelo meu trabalho como se fosse o deles. 
					Hoje não tenho que me preocupar com nada que não seja o meu trabalho, pois sei que da minha empresa eles estão cuidando.
					`,
					doctorAge: "28 anos",
					doctorName: "Dr. Wagner Fernandes",
				},
			},
			infoPlanSection: {
				buttonText: "Fale com a nossa equipe",
			},
			sectionServices: {
				title: "SERVIÇOS",
				tax: {
					title: "TRIBUTÁRIO",
					description: "Escritura, Fiscalização, Declarações, Apuração de tributos, Planejamento, Defesa de autos de infração, entre outros.",
				},
				legal: {
					title: "JURÍDICO",
					description: "Trabalhista, Previdenciária, Tributária e Cívil.",
				},
				legalization: {
					title: "LEGALIZAÇÃO",
					description: "Contratos, Alvará, Cadastro, Notas Fiscais, Certidões, Registros, Licitações...",
				},
				accounting: {
					title: "CONTÁBIL",
					description: "Declarações, Obrigações ligadas a Receita Federal, Regularidade Fiscal, Restituição, Planejamento Fiscal, Relatórios, Controle de Fluxo de Caixa e Projeção de Gastos.",
				},
				humanResources: {
					title: "RECURSOS HUMANOS",
					description: "Registro de Profissionais, Folha de Pagamento, Assessoria Trabalhista e Previdenciária, Elaboração de informações ao Ministério do Trabalho, Acessórias Anuais e Concessão de Férias.",
				},
				financialConsulting: {
					title: "CONSULTORIA FINANCEIRA",
					description: "Gestão do capital de giro e captação de recursos junto às instituições financeiras oficiais.",
				},
			},
			sectionStudio: {
				boldText: "Maior empresa brasileira de recuperação de créditos",
				text: ", auxiliamos no resgate de créditos por tributos pagos a mais, em curto prazo e sem necessidade de recorrer à Justiça.",
				buttonText: "Saiba mais",
			},
			contactForm: {
				title: "Contato",
				name: "Nome",
				email: "E-mail",
				phone: "Telefone",
				message: "Mensagem",
				send: "Enviar",
				tip: "Ainda tem dúvidas? Não esqueça de ver um tópico separado para você, com os principais questionamentos sobre os nossos serviços.",
			},
			introductionSection: {
				title: "Bomfim & Ferraz, sua contabilidade inteligente.",
				requestProposal: "Solicitar Proposta",

				description: "Contem com a experiência de quem há mais de 15 anos trabalha por soluções inovadoras para o seu sucesso.",
				list: [
					"Atendimento personalizado;",
					"Redução de custos tributários;",
					"Agilidade e segurança.",
				],
			},
			introAccountingForDoctors: {
				title: "Contabilidade para médicos.",
				requestProposal: "Solicitar Proposta",

				description: `Preocupe-se com a saúde de seus pacientes. Deixe toda a burocracia, impostos, gestão do financeiro e contratos com a Bomfim & Ferraz.
				
				`,
				list: [
					"Consultoria tributária:",
					"Pague menos impostos;" ,
					"Tenha um assessor exclusivo para seu atendimento;",
					"Abertura de CNPJ grátis;",
					"Realizamos cadastro em hospitais e clínicas;",
					"Assessoria jurídica;",
				],
				buttonText: "Fale com a nossa equipe",
			},
			contactButtons: {
				whatsapp: {
					number: "55713378-0508",
					encodedText: "Ol%C3%A1%2C+equipe+da+ContabilidadeBF%21&app_absent=0",
				},
			},
			Footer: {
				text: "Desenvolvido pela",
				company: "StartApp",
			},
		},
		stores: {
			erros: {},
		},
	},
});

export default strings;
