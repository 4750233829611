import Routes, { ILink } from "./routes";
import strings from "./strings";

interface INavigationLinks {
	name: string;
	link: ILink;
}

const mainRoutesStrings = strings.routeNames;

const navigationLinks: INavigationLinks[] = [
	{
		name: mainRoutesStrings.home,
		link: Routes.index(),
	},
	{
		name: mainRoutesStrings.services,
		link: Routes.services(),
	},
	{
		name: mainRoutesStrings.accountingForDoctors,
		link: Routes.accountingForDoctors(),
	},
	{
		name: mainRoutesStrings.faq,
		link: Routes.faq(),
	},
	{
		name: mainRoutesStrings.aboutUs,
		link: Routes.aboutUs(),
	},
];

export default navigationLinks;
