// MARK: Implementation
export interface ILink {
	href: string;
	as: string;
}

interface IRouteType {
	[key: string]: ((...x: any) => ILink) | ILink | IRouteType;
}

const getRoutes = <T extends IRouteType>(value: T): T => value;

const Routes = getRoutes({
	index: (params?: string | null) => ({
		href: "/",
		as: `/${params || ""}`,
	}),
	services: () => ({
		href: "/#services",
		as: "",
	}),
	contact: () => ({
		href: "/#contact",
		as: "",
	}),
	faq: (params?: string | null) => ({
		href: "/faq/",
		as: `/faq/${params || ""}`,
	}),
	accountingForDoctors: (params?: string | null) => ({
		href: "/accountingForDoctors/",
		as: `/accountingForDoctors/${params || ""}`,
	}),
	howWorks: (params?: string | null) => ({
		href: "/howWorks/",
		as: `/howWorks/${params || ""}`,
	}),
	blog: (params?: string | null) => ({
		href: "/blog/",
		as: `/blog/${params || ""}`,
	}),
	post: (params: string) => ({
		href: `/blog/${[params]}`,
		as: `/blog/${params || ""}`,
	}),
	aboutUs: (params?: string | null) => ({
		href: "/aboutUs/",
		as: `/aboutUs/${params || ""}`,
	}),
	taxStudio: (params?: string | null) => ({
		href: "/taxStudio/",
		as: `/taxStudio/${params || ""}`,
	}),
});

export default Routes;
