import strings from "~/resources/strings";
import Routes from "~/resources/routes";

export const categorySection = [
	{
		name: strings.routeNames.home,
		link: Routes.index(),
	},
	{
		name: strings.routeNames.services,
		link: Routes.services(),
	},
	{
		name: strings.routeNames.accountingForDoctors,
		link: Routes.accountingForDoctors(),
	},
	{
		name: strings.routeNames.faq,
		link: Routes.faq(),
	},
	{
		name: strings.routeNames.blog,
		link: Routes.blog(),
	},
	{
		name: strings.routeNames.aboutUs,
		link: Routes.aboutUs(),
	},
];
