import React from "react";
import {
	Flex,
} from "@chakra-ui/react";
import { InfoDoctorComponent } from "~/components";
import { strings } from "~/resources";

interface IProps {
}

export const InfoDoctorsSection: React.FC<IProps> = () => {

	const commonstrings = strings.components.infoDoctorsSection;

	const leftColumnData = [
		{
			src: commonstrings.left.src,
			description: commonstrings.left.description,
			doctorAge: commonstrings.left.doctorAge,
			doctorName: commonstrings.left.doctorName,
		},
	];

	const rightColumnData = [
		{
			src: commonstrings.right.src,
			description: commonstrings.right.description,
			doctorAge: commonstrings.right.doctorAge,
			doctorName: commonstrings.right.doctorName,
		},
	];

	return (
		<Flex
			backgroundColor="gray.200"
			py={10}
			px={{ base: 0, lg: 10 }}
			flexDirection={{ base:"column", md: "row" }}
			justifyContent="space-between"
		>
			<Flex
				flexDirection="column"
				w={{ base: "100%", md: "49%" }}
				alignItems="center"
			>
				{leftColumnData.map((data, index)=>(
					<InfoDoctorComponent
						key={index}
						pt={index === 0 ? 0 : 4}
						src={data.src}
						description={data.description}
						doctorAge={data.doctorAge}
						doctorName={data.doctorName}
					/>
				))}
			</Flex>
			<Flex
				flexDirection="column"
				pt={{ base: 4, md: "220px" }}
				w={{ base: "100%", md: "49%" }}
				alignItems="center"
			>
				{rightColumnData.map((data, index)=>(
					<InfoDoctorComponent
						key={index}
						pt={index === 0 ? 0 : 4}
						src={data.src}
						description={data.description}
						doctorAge={data.doctorAge}
						doctorName={data.doctorName}
					/>
				))}
			</Flex>
		</Flex>
	);
};
