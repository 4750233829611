import React from "react";
import {
	Divider,
	Flex,
	Heading,
	Text,
	Image,
} from "@chakra-ui/react";

interface IProps{
	title: string;
	description: string;
	iconSrc: string;
	iconAlt: string;
	isIconPositionLeft?: boolean;
}

export const CardWithIcon: React.FC<IProps> = (props) => {
	const {
		title,
		description,
		iconSrc,
		iconAlt,
		isIconPositionLeft,
	} = props;

	return(
		isIconPositionLeft ? (
			<Flex
				w="100%"
				maxW={850}
				justifyContent="center"
				flexDirection={{base: "column", md: "row"}}
				alignItems="center"
				position="relative"
			>
				<Image
					width="100%"
					maxW="68px"
					alt={iconAlt}
					src={iconSrc}
				/>
				<Flex
					bg="gray.100"
					maxW={450}
					borderWidth={1}
					borderRadius="16px"
					p={4}
					mx={{ base: 0, md: 10 }}
					my={10}
					flexDirection="column"
				>
					<Divider
						orientation="vertical"
						position="absolute"
						w={{ base: 0, md:1 }}
						bg="white"
						left={{ base:"23%", lg: "27%" }}
						top="13%"
						height="74%"
						borderWidth={{ base: 0, md:1 }}
						borderColor="white"
						opacity={1}
						rounded="md"
					/>
					<Heading size="lg" mb={2}>
						{title}
					</Heading>
					<Text textAlign="justify">{description}</Text>
				</Flex>
			</Flex>

		) : (
			<Flex
				w="100%"
				maxW={850}
				justifyContent="center"
				flexDirection={{ base: "column-reverse", md: "row" }}
				alignItems="center"
				position="relative"
			>
				<Flex
					bg="gray.100"
					width="100%"
					maxW={450}
					borderWidth={1}
					borderRadius="16px"
					p={4}
					mx={{ base: 0, md: 10 }}
					my={10}
					flexDirection="column"
				>
					<Divider
						maxHeight="74%"
						orientation="vertical"
						position="absolute"
						w={{ base: 0, md:1 }}
						bg="white"
						top="13%"
						right={{ base:"23%", lg: "27%" }}
						borderWidth={{ base: 0, md:1 }}
						borderColor="white"
						opacity={1}
						rounded="md"
					/>
					<Heading size="lg" mb={2}>
						{title}
					</Heading>
					<Text textAlign="justify">{description}</Text>
				</Flex>
				<Image
					width="100%"
					maxW="68px"
					alt={iconAlt}
					src={iconSrc}
				/>
			</Flex>
		)
	);
};
