import { ComponentStyleConfig } from "@chakra-ui/react";
import { radioAnatomy } from "@chakra-ui/anatomy";

const parts = radioAnatomy.classNames;
const { container, control, label } = parts;

export const Radio: ComponentStyleConfig = {
	parts: [container, control, label],
	baseStyle: {
		control: {
			_focus: {
				boxShadow: "none",
			},
		},
	},
};
