import React from "react";
import {
	Flex,
	CenterProps,
	Heading,
	Image,
	Text,
} from "@chakra-ui/react";
import { InfoText} from "~/components";
import spacing from "~/themes/spacing";
import { strings } from "~/resources";
import { CheckIcon } from "@chakra-ui/icons";

interface IProps extends CenterProps {
	list: string[];
}

const componentStrings = strings.components.introAccountingForDoctors;

export const IntroInfoDoctorsSection: React.FC<IProps> = (props) => {

	const {
		list,
		...rest
	} = props;
	return (
		<Flex
			width="100%"
			justifyContent="center"
			flexWrap={{ base:"wrap", lg: "initial" }}
			flexDirection="row"
			{...rest}
		>
			<Image
				width={{ base: "100%", lg: "60%" }}
				height={{ base: 350, md: 500, lg: 650 }}
				alt="médico"
				src="/doctor.png"
				bgSize="cover"
				bgPosition="center"
				left="10%"
			/>
			<Flex
				flexDirection={{base:"column", md:"row", lg:"column"}}
				ml={{ base: "auto", md: "initial", lg: "auto" }}
				mr={{ base: "auto", md: "initial", lg: "120px" }}
				maxW={{ base: 400, md: "100%", lg: 400 }}
				zIndex="9"
				pb={10}
				px={4}
				pt={{ base: "initial", md: spacing.s8, lg: "initial" }}
			>
				<Heading
					fontSize="40px"
					fontWeight="bold"
					textAlign="left"
					mb={spacing.s10}
					mt={{base:spacing.s8, md: "initial", lg: spacing.s16}}
				>
					{componentStrings.title}
				</Heading>
				<Flex flexDirection="column" pl={{ base: "initial", md: spacing.s16, lg: "initial" }}>
					<InfoText
						textAlign="justify"
						fontSizeText="md"
						text={componentStrings.description}
						mb={5}
					/>
					{
						list && list.map((item: string, index: number) =>
							(
								<Flex key={index} alignItems="center">
									<CheckIcon />
									<Text ml={5} mb={1}>{item}</Text>
								</Flex>
							),
						)
					}
				</Flex>
			</Flex>
		</Flex>
	);
};
