import React from "react";
import {
	Box,
	Text,
	Image,
	useMediaQuery,
	Flex,
} from "@chakra-ui/react";
import { MobileDrawer, LinkButton } from "../../../components";
import { DrawerContent } from "../DrawerContent";
import navigationLinks from "../../../resources/navigationLinks";

interface IProps {
	currentPage: string;
}

export const NavbarLinks: React.FC<IProps> = (props) => {
	const [isLargerThan] = useMediaQuery("(min-width: 800px)");
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const { currentPage } = props;
	const fontSizeBreakPoint = { base: "sm", lg: "xl" };

	return (
		isLargerThan ? (
			<Box display="flex" ml="4" alignItems="center">
				{navigationLinks.map((navigation) => (
					<Flex
						key={navigation.name}
						flexDirection="column"
						marginRight={"6px"}
					>

						{navigation.link.href === currentPage && (
							<Box
								alignSelf="center"
								mr="5"
								h={1}
								w={"100%"}
								bg="primary.700"
								position="relative"
								bottom={2}
							/>
						)}
						<LinkButton
							key={navigation.name}
							routeLink={navigation.link}
							color="primary.400"
						>
							<Text
								cursor="pointer"
								color="primary.700"
								fontSize={fontSizeBreakPoint}
								mr="5"
								bottom={3}
								fontWeight={navigation.link.href === currentPage ? "bold" : "normal"}
							>
								{navigation.name}
							</Text>
						</LinkButton>
					</Flex>
				))}
			</Box>
		) : (
			<>
				<Image
					alt="menu-icon"
					src="/menuIcon.svg"
					w={50}
					onClick={() => setIsOpen(true)}
					cursor="pointer"
				/>
				<MobileDrawer
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					drawerContentProps={{ borderTopRightRadius: 20, borderTopLeftRadius: 20 }}
				>
					<DrawerContent onClose={() => setIsOpen(false)} />
				</MobileDrawer>
			</>
		)
	);
};
