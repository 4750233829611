import React from "react";
import {
	Flex,
	CenterProps,
	Heading,
	Button,
	Text,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { MdReceipt } from "react-icons/md";
import { InfoText } from "~/components";
import spacing from "~/themes/spacing";
import { strings } from "~/resources";
import { useRouter } from "next/router";
import NextImage from "next/image";

interface IProps extends CenterProps {
	srcImage: string;
	title: string;
	description: string;
	list?: string[];
	isAboutUsPage?: boolean;
	hideButton?: boolean;
}

export const IntroductionSection: React.FC<IProps> = (props) => {

	const {
		title, description, srcImage,
		list, hideButton, isAboutUsPage,
		...rest
	} = props;

	const componentStrings = strings.components.introductionSection;

	const router = useRouter();

	const goToContact = () => {
		router.push("/#contact");
	};

	return (
		<Flex
			width="100%"
			justifyContent="space-between"
			flexWrap={{ base: "wrap", lg: "initial" }}
			flexDirection={{ base: "column-reverse", lg: "row" }}
			bg="gray.100"
			{...rest}
		>
			<Flex
				flexDirection="column"
				w={{ base: "100%", md: "95%", lg: "40%" }}
				mx={{ base: 0, lg: 8 }}
				ml={{ base: 0, md: 6, lg: "80px" }}
				justifyContent="center"
				my={{ base: 8, lg: 0 }}
			>
				<Heading
					fontSize={{ base: "40px", lg: "60px" }}
					fontWeight="bold"
					mx={{ base: 5, lg: 0 }}
					mt={6}
					mb={6}
				>
					{title}
				</Heading>
				<Flex
					flexDirection="column"
					justifyContent={{ base: "center", lg: "flex-start" }}
					alignItems={{ base: "center", lg: "flex-start" }}
					fontSize="20px"
					mb={{ base: 5, lg: 8 }}
					mx={{ base: 5, lg: 0 }}
				>
					<InfoText
						textAlign="justify"
						mb={7}
						fontSizeText="md"
						text={description}
					/>
					{list &&
						list.map((item: string, index: number) => (
							<Flex key={index} alignItems="center">
								<CheckIcon />
								<Text
									ml={5}
									mb={1}
								>
									{item}
								</Text>
							</Flex>
						))
					}
					{!hideButton && (
						<Button
							mt={spacing.s8}
							leftIcon={<MdReceipt />}
							backgroundColor="primary.800"
							onClick={() => goToContact()}
						>
							{componentStrings.requestProposal}
						</Button>
					)}
				</Flex>
			</Flex>
			{isAboutUsPage ?
				<Flex
					width={{ base: "100%", lg: "65%" }}
					justifyContent="center"
					alignItems="center"
					flexGrow={1}
				>
					<NextImage
						width={900}
						quality={100}
						height={700}
						layout="intrinsic"
						alt="office-image"
						src={srcImage}
					/>
				</Flex>
				:
				<NextImage
					width={1000}
					quality={100}
					height={750}
					layout="intrinsic"
					alt="office-image"
					src={srcImage}
				/>
			}

		</Flex>
	);
};
