import React from "react";
import {
	Flex,
	Heading,
} from "@chakra-ui/react";
import { CardWithIcon } from "..";
import { strings } from "~/resources";

export const SectionServices: React.FC = () => {

	const componentStrings = strings.components.sectionServices;

	const cards = [
		{
			title: componentStrings.tax.title,
			description: componentStrings.tax.description,
			iconSrc: "calculatorIcon.svg",
			iconAlt: "calculator-icon",
			isIconPositionLeft: true,
		},
		{
			title: componentStrings.legal.title,
			description: componentStrings.legal.description,
			iconSrc: "graphicIcon.svg",
			iconAlt: "graphic-icon",
			isIconPositionLeft: false,
		},
		{
			title: componentStrings.legalization.title,
			description: componentStrings.legalization.description,
			iconSrc: "listIcon.svg",
			iconAlt: "list-icon",
			isIconPositionLeft: true,
		},
		{
			title: componentStrings.accounting.title,
			description: componentStrings.accounting.description,
			iconSrc: "openMailIcon.svg",
			iconAlt: "openMail-icon",
			isIconPositionLeft: false,
		},
		{
			title: componentStrings.humanResources.title,
			description: componentStrings.humanResources.description,
			iconSrc: "computerIcon.svg",
			iconAlt: "computer-icon",
			isIconPositionLeft: true,
		},
		{
			title: componentStrings.financialConsulting.title,
			description: componentStrings.financialConsulting.description,
			iconSrc: "moneyIcon.svg",
			iconAlt: "money-icon",
			isIconPositionLeft: false,
		},
	];

	return(
		<Flex
			id="services"
			backgroundImage="background-services.png"
			backgroundPosition="center"
			backgroundSize="cover"
		>
			<Flex
				background="rgba(152, 143, 8, 0.84)"
				width="100%"
				alignItems="center"
				px={5}
				py={8}
				flexDirection="column"
			>
				<Heading size="2xl" color="white" mb={6}>
					{componentStrings.title}
				</Heading>
				{cards.map((card, index)=>(
					<CardWithIcon
						key={index}
						title={card.title}
						description={card.description}
						iconSrc={card.iconSrc}
						iconAlt={card.iconAlt}
						isIconPositionLeft={card.isIconPositionLeft}
					/>
				))}
			</Flex>
		</Flex>
	);
};
