import React from "react";
import Image from "next/image";
import { Flex, Link, keyframes } from "@chakra-ui/react";
import spacing from "~/themes/spacing";
import { strings } from "~/resources";

export const ContactButtons: React.FC = () => {
	const componentStrings = strings.components.contactButtons;
	const pulse = keyframes`
		0% {
			transform: scale(0.9);
		}
		70% {
			transform: scale(1);
			box-shadow: 0 0 0 40px transparent;
		}
		100% {
			transform: scale(0.9);
			box-shadow: 0 0 0 0 transparent;
		}
	`;
	const buttonAnimation = `2s ease infinite ${pulse}`;
	return (
		<Flex
			zIndex={999}
			position="fixed"
			bottom={spacing.s6}
			right={spacing.s6}
		>
			<Link
				href={`https://api.whatsapp.com/send/?phone=%2B${componentStrings.whatsapp.number}&text=${componentStrings.whatsapp.encodedText}`}
				target="_blank"
				rel="noreferrer"
				animation={buttonAnimation}
				_hover={{ animation: "none" }}
				w="58px"
				h="58px"
				borderRadius="50%"
				boxShadow="0 0 0 0 rgba(118, 174, 106, 0.5)"
			>
				<Image src="/wpp.svg" alt="Whatsapp" height={58} width={58} />
			</Link>
		</Flex>
	);
};
