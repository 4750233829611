import React from "react";
import { Box, Image } from "@chakra-ui/react";

import { NavbarLinks } from "./NavbarLinks";
import { useRouter } from "next/router";

export const Navbar: React.FC = () => {
	const router = useRouter();

	return (
		<Box
			maxW={1100}
			mx="auto"
			px={{base: "20px" , lg: "20px"}}
			py="15px"
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			bg="white"
		>
			<Image
				alt="contabilidade-B&F-logo"
				src="/logo.png"
				h={{base:"50px", sm: "40px"}}
				w={{base:"180px", sm: "180px"}}
			/>
			<NavbarLinks currentPage={router.asPath} />
		</Box>
	);
};
