import React from "react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	Box,
	AccordionPanel,
	Heading,
	Divider,
} from "@chakra-ui/react";

interface IProps {
	items: Array<{
		title: string;
		description: string;
	}>;
}

export const FaqComponent: React.FC<IProps> = (props) => {
	const { items } = props;

	return(
		<Accordion allowToggle allowMultiple>
			{items.map((item, index)=>(
				<AccordionItem
					key={index}
					p={2}
					my={2}
					backgroundColor="white"
					borderRadius="md"
				>
					{({ isExpanded }) => (
						<>
							<AccordionButton>
								<Box flex="1" textAlign="left" pr={10}>
									<Heading fontSize="md">{item.title}</Heading>
								</Box>
								{isExpanded ? (
									<MinusIcon fontSize="12px" color="gray.500" />
								) : (
									<AddIcon fontSize="12px" color="gray.500" />
								)}
							</AccordionButton>
							<AccordionPanel>
								<Divider my={4} />
								{item.description}
							</AccordionPanel>
						</>
					)}
				</AccordionItem>
			))}
		</Accordion>
	);
};
