import React from "react";
import NextHead from "next/head";

export const SiteHead: React.FC = () =>  {
	const googleAnalyticsId = "G-268F2J390L";
	// const KEY = "AIzaSyDINZhV4FEfXkRE_7omwnxYWJStUERje3E";
	return (
		<NextHead>
			<meta
				key="viewport"
				name="viewport"
				content="width=device-width, initial-scale=1, shrink-to-fit=no"
			/>
			<meta name="title" content="Contabilidade Bomfim & Ferraz" />
			<link rel="icon" href="/short-logo.png" />
			<link rel="manifest" href="/short-logo.png" />
			<link rel="apple-touch-icon" href="/short-logo.png" />
			{process.env.APP_ENV === "production" ?
				(
					<>
						<script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`} />
						{/* <script async defer src={`https://maps.googleapis.com/maps/api/js?key=${KEY}&callback=initMap`} /> */}
						<script dangerouslySetInnerHTML={{ __html: `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${googleAnalyticsId}');` }} />
					</>
				) :
				(
					<meta name="robots" content="noindex" />
				)
			}
		</NextHead>
	);
};
