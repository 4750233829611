import React from "react";
import {
	Box,
	Button,
	Center,
	Divider,
	Flex,
	IconButton,
	Image,
	Text,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { strings } from "~/resources";

interface IProps{
	onClick?: () => void;
}

export const SectionStudio: React.FC<IProps> = (props) => {
	const { onClick } = props;
	const componentStrings = strings.components.sectionStudio;

	return(
		<Flex
			backgroundImage="/background-studio.png"
			backgroundPosition="center"
			backgroundSize="cover"
		>
			<Flex
				background="rgba(0, 0, 0, 0.7)"
				width="100%"
				alignItems="center"
				p={5}
				flexDirection="column"
			>

				<Center flexDirection="column" w="100%">
					<Image src="/logo-white.png" alt="logo-white" mt={10}/>
					<Box w="100%" maxW={450} position="relative" m={20}>
						<IconButton
							aria-label="Add"
							icon={<AddIcon />}
							position="absolute"
							left="50%"
							cursor="initial"
							_hover={{
								bgColor: "primary.700",
							}}
							_focus={{ borderWidth: 0 }}
							zIndex={999}
							top={0}
							maxW="10px"
							rounded="full"
							bgColor="primary.700"
							p={8}
							transform="translate(-50%, -50%)"
						/>
						<Divider />
					</Box>
					<Image src="/studio-fiscal.png" alt="studio-fiscal" />
				</Center>
				<Flex w="100%" maxW={850} justifyContent="center">
					<Flex
						maxW={350}
						borderWidth={1}
						borderRadius="32px"
						p={4}
						position="relative"
						m={10}
					>
						<AddIcon
							w="10px"
							color="white"
							position="absolute"
							left={0}
							top={-2}
						/>
						<Text color="white" fontSize="18px" >
							<Text
								display="inline"
								fontWeight="bold"
								fontSize="18px"
								color="white"
							>
								{componentStrings.boldText}
							</Text>
							{componentStrings.text}
						</Text>
					</Flex>
				</Flex>
				{onClick && (
					<Button
						bgColor="primary.700"
						borderRadius="lg"
						width="max-content"
						p={6}
						onClick={onClick}
					>
						{componentStrings.buttonText}
					</Button>
				)}
			</Flex>
		</Flex>
	);
};
