import React from "react";
import { observer } from "mobx-react-lite";
import {
	Box,
	BoxProps,
	Input,
	InputProps,
} from "@chakra-ui/react";
import { Label } from "../";
import spacing from "../../themes/spacing";
import InputMask from "react-input-mask";

export interface IProps extends Omit<InputProps, "value">{
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	labelText?: string;
	value?: string | null;
	helper?: string;
	boxProps?: BoxProps;
	mask?: string;
	inputRef?: React.RefObject<HTMLInputElement>;
}

export const TextInput: React.FC<IProps> = observer((props) => {
	const {
		onChange,
		labelText,
		helper,
		boxProps,
		value,
		mask,
		inputRef,
		...rest
	} = props;

	return(
		<Box {...boxProps}>
			<Label marginBottom={spacing.s1} helper={helper}>{labelText}</Label>
			<Input
				ref={inputRef}
				onChange={onChange}
				value={value}
				as={mask ? InputMask : undefined}
				mask={mask}
				{...rest}
			/>
		</Box>
	);
});
