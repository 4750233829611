import React from "react";
import { observer } from "mobx-react-lite";
import {
	Box,
	Flex,
	Image,
	Text,
	VStack,
} from "@chakra-ui/react";
import { categorySection } from "./categorySection";
import { LinkButton } from "~/components";

export const Footer: React.FC = observer(() => {
	const fontSizeBreakPoint = { md: "md", lg: "xl" };

	const onClickGTagEvent = (eventName: string) => {
		if (typeof gtag !== "undefined") {
			gtag("event", eventName, {
				event_category: "Site Forms",
			});
		}
	};

	return (
		<Flex
			py={20}
			px={{ base: 5, sm: 50, lg: 100 }}
			justifyContent={{ base: "center", md: "space-between" }}
			alignItems="flex-start"
		>

			<VStack
				width={{ base: "80%", md: "25%" }}
				alignItems={{ base: "center", md: "flex-start" }}
				spacing={5}
				mr={{ base: 0 , md: 20 }}
			>
				<Image
					alt="Contabilidade B&F-logo"
					src="/logo.png"
					h="4rem"
				/>
			</VStack>
			<Flex>
				<Box
					mr={{ base: 0, md: 5 }}
					display={{ base: "none", md: "flex" }}
					flexDirection="column"
				>
					{categorySection.map((navigation) => (
						<LinkButton
							key={navigation.name}
							routeLink={navigation.link}
						>
							<Text
								cursor="pointer"
								color="primary.700"
								fontSize={fontSizeBreakPoint}
								mr="5"
								fontWeight="bold"
								textAlign="end"
								onClick={()=>onClickGTagEvent(`${navigation.name} Clicked`)}
							>
								{navigation.name}
							</Text>
						</LinkButton>
					))}
				</Box>
			</Flex>
		</Flex>
	);
});

