import { ComponentStyleConfig } from "@chakra-ui/react";
import { theme } from "@chakra-ui/theme";

export const Button: ComponentStyleConfig = {
	baseStyle: {
		color: "white",
		fontWeight: "bold",
		borderRadius: "md",
		width: "100%",
		maxWidth: "450px",
	},
	defaultProps: {
		variant: "solid",
		colorScheme: "primary",
	},
	variants: {
		outline: (props) => ({
			...theme.components.Button.variants.outline(props),
			borderWidth: "1px",
		}),
		link: ({ colorScheme}) => ({
			color: `${colorScheme}.500`,
			textDecoration: "underline",
			fontSize: "sm",
		}),
	},
};
